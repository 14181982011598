import MoviesLogo from "../../images/svg/moviesLogo.svg";
import LittleLogo from "../../images/svg/littleLogo.svg";
import TherapyLogo from "../../images/svg/therapy_logo.svg";
import EventsLogo from "../../images/png/EVENTS_BLACK-.png";
import LittlePay from "../../images/png/littlePayLogo.png";
export const projects = [
  {
    title: "Little Pay",
    description:
      "A payment gateway based in Kenya. Supports card and mobile money payments. I built both the backend and frontend for this project.",
    stack: "React JS, Fastify, MySQL",
    url: "https://pay.little.africa",
    logo: LittlePay,
    height: "20%",
  },
  {
    title: "Little events",
    description:
      "A website for selling event tickets in Kenya and Ghana. Event organisers can upload events and sell tickets to their customers. They also have access to reports and analytics for their sales. Events uploaded on the Little Events portal are also visible on the Little App. I built both the backend and frontend for this project.",
    stack: "Next JS, MySQL",
    url: "https://apps.little.africa/events",
    logo: EventsLogo,
    height: "20%",
  },

  {
    title: "Order a ride",
    description:
      "Order a ride from Little on the web. Similar to Uber web app. This project uses Socket IO for real-time updates on the driver's location and the ride status. I built both the backend and frontend for this project",
    stack: "React JS, Socket.IO",
    url: "https://ride.little.africa/",
    logo: LittleLogo,
    height: "40%",
  },
  {
    title: "Little movies",
    description: "A website for booking movies.",
    stack: "React JS, Express JS",
    url: "https://apps.little.africa/movies",
    logo: MoviesLogo,
    height: "20%",
  },

  {
    title: "Little App (Web)",
    description: "Little app on the web.",
    stack: "React JS, Express JS",
    url: "https://apps.little.africa/",
    logo: LittleLogo,
    height: "40%",
  },

  // {
  //   title: "Therapy4You",
  //   description:
  //     "A submission for the 2022 Rusinga Hackathon. This project earned 2nd place.",
  //   stack: "React JS, Flask, Firebase",
  //   url: "https://mental-health-app.vercel.app/",
  //   logo: TherapyLogo,
  //   height: "80%",
  // },
];
