import {
  Alert,
  Box,
  Button,
  CircularProgress,
  Container,
  Snackbar,
  Stack,
  TextField,
  Typography,
  useMediaQuery,
} from "@mui/material";
import ContactPlatform from "./contactPlatform";
import { ReactComponent as Shapes } from "../../images/svg/shapes.svg";
import { AnimatePresence, motion } from "framer-motion";
import BgText from "./bgText";
import { useState } from "react";
import sendMessage from "../../api/sendMesage";
import { CheckCircle } from "@mui/icons-material";

const ContactMe = () => {
  const isMob = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const isMd = useMediaQuery((theme) => theme.breakpoints.up("sm"));

  const [email, setEmail] = useState("");
  const [fullName, setFullName] = useState("");
  const [message, setMessage] = useState("");
  const [addToMailList] = useState(false);
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState("Message sent successfully");
  const [severity, setSeverity] = useState("success");
  const [sending, setSending] = useState(false);
  const [sent, setSent] = useState(false);

  const submitMessage = () => {
    if (email && fullName && message) {
      const test = false;
      if (test) {
        setSending(true);
        setTimeout(() => {
          setSent(true);
          setSending(false);
          setAlertMessage("Message sent successfully");
          setSeverity("success");
          setAlertOpen(true);
        }, 2000);
      } else {
        setSending(true);
        sendMessage({
          fullName,
          email,
          message,
          addToMailList,
        })
          .then(() => {
            setSent(true);
            setSending(false);
            setAlertMessage("Message sent successfully");
            setSeverity("success");
            setAlertOpen(true);
          })
          .catch((error) => {
            setAlertMessage("Something went wrong");
            setSeverity("error");
            setAlertOpen(true);
          });
      }
    } else {
      setAlertMessage("Please fill out all fields");
      setSeverity("error");
      setAlertOpen(true);
    }
  };
  return (
    <Stack
      sx={{
        width: "100%",
        ...(isMd && { height: "100vh" }),
        alignItems: "center",
        position: "relative",
      }}
      spacing={4}
      id="contactMe"
    >
      <BgText>Contact me</BgText>
      <Stack justifyContent="center" flexGrow={2}>
        <Shapes
          height={isMob ? "2.5vh" : "5vh"}
          width={isMob ? "10vh" : "20vh"}
        />
      </Stack>
      <Container
        maxWidth="lg"
        sx={{
          display: "flex",
          justifyContent: "center",
          flexGrow: 4,
        }}
      >
        <Stack
          direction={{ xs: "column", md: "row" }}
          width="90%"
          component={motion.div}
          initial={{ y: 100, opacity: 0 }}
          whileInView={{ y: 0, opacity: 1 }}
          viewport={{ once: true }}
          transition={{ ease: "easeOut", duration: 0.7 }}
          spacing={2}
        >
          <Stack spacing={2} flexGrow={3}>
            <Typography color={"white"} variant="h5">
              Contact Me
            </Typography>
            <ContactPlatform title="Linkedin">
              <Typography
                component="a"
                sx={{ textDecoration: "none", color: "white" }}
                href="https://www.linkedin.com/in/daniel-maina-8449bb23b/"
              >
                Linkedin
              </Typography>
            </ContactPlatform>
            <ContactPlatform title="Github">
              <Typography
                component="a"
                sx={{ textDecoration: "none", color: "white" }}
                href="https://github.com/danielobima"
              >
                Github
              </Typography>
            </ContactPlatform>
            <ContactPlatform title="Email">
              <Typography
                component="a"
                sx={{ textDecoration: "none", color: "white" }}
                href="mailto:mail@danielobima.com"
              >
                Email
              </Typography>
            </ContactPlatform>
          </Stack>
          <Stack
            flexGrow={6}
            key="stack1"
            sx={{ position: "relative", minHeight: "60vh" }}
          >
            <AnimatePresence>
              {" "}
              {!sent ? (
                <Stack
                  spacing={2}
                  key="stack1"
                  component={motion.div}
                  animate={{ opacity: 1, y: 0 }}
                  exit={{ opacity: 0, y: 50 }}
                  transition={{ ease: "easeOut", duration: 0.5 }}
                >
                  <Typography color={"white"} variant="h5">
                    Leave a message
                  </Typography>
                  <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
                    <Stack flexGrow={1} spacing={1}>
                      <TextField
                        label="Full name"
                        type="text"
                        name="name"
                        variant="standard"
                        onChange={(event) => setFullName(event.target.value)}
                        value={fullName}
                        sx={{
                          "&:-webkit-autofill": {
                            backgroundColor: "green",
                            color: "white",
                          },
                        }}
                      />
                    </Stack>
                    <Stack flexGrow={1} spacing={1}>
                      <TextField
                        label="Email"
                        variant="standard"
                        name="email"
                        type="text"
                        onChange={(event) => setEmail(event.target.value)}
                        value={email}
                        sx={{
                          "&:-internal-autofill-selected": {
                            backgroundColor: "none",
                            color: "white",
                          },
                        }}
                      />
                    </Stack>
                  </Stack>
                  <Stack spacing={1}>
                    <Box
                      component="textarea"
                      sx={{
                        background: "none",
                        width: "100%",
                        height: "25vh",
                        border: "1px solid aaaaaa",
                        transition: "border 0.3s",
                        borderRadius: "7px",
                        fontFamily: "Montserrat",
                        color: "white",
                        backgroundColor: "none !important",
                        resize: "none",
                      }}
                      value={message}
                      onChange={(event) => setMessage(event.target.value)}
                      placeholder="Enter your message"
                    />

                    <Button onClick={submitMessage} disabled={sending}>
                      {sending && (
                        <>
                          <CircularProgress size={"16px"} />
                          &nbsp;
                        </>
                      )}
                      Submit
                    </Button>
                  </Stack>
                </Stack>
              ) : (
                <>
                  <Stack
                    sx={{
                      position: "absolute",
                      width: "100%",
                    }}
                    key="stack2"
                    component={motion.div}
                    alignItems="center"
                    spacing={3}
                    initial={{ opacity: 0, y: 50 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ ease: "easeOut", duration: 0.5 }}
                  >
                    <CheckCircle fontSize="large" htmlColor="white" />
                    <Typography color={"white"} variant="h5" fontWeight={300}>
                      Thanks I will get back to you as soon as possible!
                    </Typography>
                  </Stack>
                  <Typography
                    color={"white"}
                    variant="h5"
                    p={5}
                    fontWeight={300}
                    sx={{ opacity: 0 }}
                  >
                    Thanks I will get back to you as soon as possible!
                  </Typography>
                </>
              )}
            </AnimatePresence>
          </Stack>
        </Stack>
      </Container>
      <Stack
        alignItems={{ xs: "center", md: "start" }}
        flexGrow={1}
        width="90%"
        py={2}
      >
        <Typography variant="body1" color={"white"}>
          &#169; Copyright Daniel Obima 2024
        </Typography>
      </Stack>
      <Snackbar open={alertOpen} onClose={() => setAlertOpen(false)}>
        <Alert severity={severity}>{alertMessage}</Alert>
      </Snackbar>
    </Stack>
  );
};
export default ContactMe;
