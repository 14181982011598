import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";

const ProjectDialog = ({ open, setOpen, selectedProject, setAlertOpen }) => {
  return (
    <Dialog
      open={open}
      onClose={() => setOpen(false)}
      maxWidth="sm"
      fullWidth={true}
    >
      <DialogTitle>{selectedProject.title}</DialogTitle>
      <DialogContent>
        <DialogContentText>{selectedProject.description}</DialogContentText>
        <br />
        <DialogContentText>
          <strong>Stack:&nbsp;</strong>
          {selectedProject.stack}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setOpen(false)}>Close</Button>
        <Button
          onClick={() => {
            selectedProject.url
              ? window.open(selectedProject.url, "_blank")
              : setAlertOpen(true);
            setOpen(false);
          }}
        >
          Open project
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ProjectDialog;
