import { Stack, Typography } from "@mui/material";
import { motion } from "framer-motion";
import { colors } from "../../utilities/colors";
const Hello = () => {
  return (
    <Stack>
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.5 }}
      >
        <Typography variant="h2" fontWeight={300} color={"white"}>
          Hello<span style={{ color: colors["Gold Web Golden"] }}>,</span>
        </Typography>
      </motion.div>
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.5, delay: 1 }}
      >
        <Typography variant="h3" fontWeight={300} color={"white"}>
          I'm Daniel Obima
        </Typography>
      </motion.div>
    </Stack>
  );
};
export default Hello;
