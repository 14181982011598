import { Typography, useMediaQuery } from "@mui/material";

const BgText = ({ children }) => {
  const isMd = useMediaQuery((theme) => theme.breakpoints.up("sm"));
  return (
    <>
      {isMd && (
        <Typography
          component="p"
          sx={{
            position: "absolute",
            bottom: 0,
            right: 0,
            margin: "16px",
            fontFamily: "Bitter",
            fontWeight: 700,
            cursor: "pointer",
            userSelect: "none",
            color: "black",
            opacity: 0.25,
            lineHeight: "1",
            zIndex: -1,
            fontSize: "10rem",
          }}
        >
          {children}
        </Typography>
      )}
    </>
  );
};

export default BgText;
