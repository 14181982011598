import "./App.css";
import { createTheme, responsiveFontSizes, ThemeProvider } from "@mui/material";
import HomePage from "./pages/homePage";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { colors } from "./utilities/colors";

function App() {
  let theme = createTheme({
    shape: {
      borderRadius: 0,
    },
    typography: {
      fontFamily: "Montserrat, Inter",
    },
    palette: {
      mode: "dark",
      primary: {
        main: colors["Uranian Blue"],
      },
    },
  });
  theme = responsiveFontSizes(theme);
  return (
    <ThemeProvider theme={theme}>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<HomePage />} />
        </Routes>
      </BrowserRouter>
    </ThemeProvider>
  );
}

export default App;
