import { Box, Stack } from "@mui/material";
import { ReactComponent as Linkedin } from "../../images/svg/linkedin.svg";
import { ReactComponent as Github } from "../../images/svg/github.svg";
import { EmailRounded } from "@mui/icons-material";
import { colors } from "../../utilities/colors";
import Atropos from "atropos/react";
import HeaderButton from "./headerButton";

const ContactPlatform = ({
  title = "Linkedin",
  href = "/nice",
  text,
  onClick = () => {},
  children,
}) => {
  return (
    <Stack direction="row" alignItems={"center"} spacing={2}>
      <Box
        sx={{
          width: "8vh",
          height: "8vh",
          borderRadius: "500px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
        component={Atropos}
        shadowScale={0.2}
        highlight={false}
      >
        <Box
          sx={{
            bgcolor: "white",
            width: "8vh",
            height: "8vh",
            borderRadius: "500px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {title === "Linkedin" && (
            <Linkedin width={"50%"} height="50%" data-atropos-offset="5" />
          )}
          {title === "Github" && (
            <Github width={"50%"} height="50%" data-atropos-offset="5" />
          )}
          {title === "Email" && (
            <EmailRounded
              data-atropos-offset="5"
              fontSize="large"
              htmlColor={colors["Prussian Blue"]}
            />
          )}
        </Box>
      </Box>

      {text ? (
        <HeaderButton text={text ?? title} onClick={onClick} />
      ) : (
        <HeaderButton onClick={onClick}>{children}</HeaderButton>
      )}
    </Stack>
  );
};

export default ContactPlatform;
