import { Stack } from "@mui/material";
import { useRef } from "react";

const Background = ({ children }) => {
  const containerRef = useRef();
  return (
    <Stack
      ref={containerRef}
      sx={{
        width: "100%",
        minHeight: "100vh",
        position: "relative",
      }}
      spacing={4}
    >
      {children}
    </Stack>
  );
};

export default Background;
