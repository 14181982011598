import { Box, Stack } from "@mui/material";
import ContactMe from "./components/contactMe";
import ProjectList from "./components/projectList";
import Background from "./components/background";
import About from "./components/about";
const HomePage = () => {
  return (
    <Stack>
      <Box sx={{ width: "100%" }}>
        <Background>
          <About />
          <ProjectList />
          <ContactMe />
        </Background>
      </Box>
    </Stack>
  );
};

export default HomePage;
