import { Stack, Typography, Box } from "@mui/material";
import { useState } from "react";
import { colors } from "../../utilities/colors";

const HeaderButton = ({
  text = "Button",
  onClick = () => {},
  selected = false,
  children,
}) => {
  const [hover, setHover] = useState(false);
  const [mouseDown, setMouseDown] = useState(false);
  return (
    <Stack
      onMouseOver={() => setHover(true)}
      onMouseOut={() => {
        setHover(false);
        setMouseDown(false);
      }}
      onClick={onClick}
      onDrag={() => setMouseDown(false)}
      onMouseDown={() => setMouseDown(true)}
      onMouseUp={() => setMouseDown(false)}
      sx={{ cursor: "pointer" }}
    >
      <Typography
        variant="body1"
        color={mouseDown ? colors["Uranian Blue"] : "white"}
        sx={{ transition: "color 0.3s", userSelect: "none" }}
      >
        {children ?? text}
      </Typography>
      <Box
        sx={{
          width: hover || selected ? "100%" : "0%",
          height: "2px",
          bgcolor: mouseDown ? colors["Uranian Blue"] : "white",
          transition: "width 0.3s, color 0.3s",
        }}
      />
    </Stack>
  );
};

export default HeaderButton;
