import axios from "axios";
import { baseUrl } from "./baseURL";

const sendMessage = ({ fullName, email, message, addToMailList = true }) =>
  new Promise((resolve, reject) => {
    axios
      .post(`${baseUrl}/message/submit`, {
        fullName,
        email,
        message,
        addToMailList,
      })
      .then((response) => {
        resolve(response);
      })
      .catch(reject);
  });

export default sendMessage;
