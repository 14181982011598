import { Stack, Typography, Box, useMediaQuery } from "@mui/material";
import { motion } from "framer-motion";
import { ReactComponent as Lines1 } from "../../images/svg/lines1Anim.svg";
import "../../images/svg/lines1Anim.css";

const Skills = () => {
  const isSm = useMediaQuery((theme) => theme.breakpoints.down("md"));
  //const isMob = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  return (
    <Stack
      component={motion.div}
      initial={{ opacity: 0, ...(!isSm ? { x: 0, y: 0 } : { y: 100, x: 0 }) }}
      animate={{ opacity: 1, x: 0, y: 0 }}
      transition={{ ease: "easeOut", duration: 0.7, delay: 2 }}
      sx={{
        width: { xs: "100%", md: "40vw" },
        position: "relative",
        height: "fit-content",
      }}
    >
      {!isSm ? (
        <Box sx={{ position: "absolute", height: "100%" }}>
          <Lines1 height={"300px"} width="150px" className="active" />
        </Box>
      ) : (
        <Box
          sx={{
            mt: 2,
            width: "15vw",
            height: "0.5vh",
            borderRadius: "500px",
            bgcolor: "white",
          }}
        />
      )}
      <Stack width="90%" pt={isSm ? 2 : 4} pl={isSm ? 0 : 4} spacing={3}>
        <Typography variant="h4" fontWeight={400} color={"white"}>
          What I do
        </Typography>

        <Stack spacing={2}>
          {/* <Stack direction="row" alignItems={"center"} spacing={1}>
            <Code height={"20px"} width="20px" />
            <Typography variant="h5" fontWeight={400} color={"white"}>
              Development
            </Typography>
          </Stack> */}
          <Typography variant="body1" color={"white"} fontWeight={300}>
            I do software development. I primarily use Javascript/Typescript.
            I've worked with multiple frameworks like React, Express and
            Flutter.
          </Typography>
          <Typography variant="body1" color={"white"} fontWeight={300}>
            I currently work at{" "}
            <Typography
              component={"a"}
              href="https://little.africa/"
              sx={{ color: "white" }}
            >
              Little
            </Typography>{" "}
            (2022 - Present) as a Software Engineer. I build web applications
            and services.
          </Typography>
        </Stack>
      </Stack>
    </Stack>
  );
};

export default Skills;
