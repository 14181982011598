import {
  Alert,
  Box,
  Grid,
  Snackbar,
  Stack,
  Typography,
  useMediaQuery,
} from "@mui/material";
import "../../images/svg/logoAnim.css";
import ProjectComponent from "./projectComponent";
import { motion } from "framer-motion";
import { useState } from "react";
import MoviesLogo from "../../images/svg/moviesLogo.svg";
import BgText from "./bgText";
import ProjectDialog from "./projectDialog";
import { projects } from "./projects";

const ProjectList = () => {
  const [projectDialogOpen, setDialogOpen] = useState(false);
  const [alertOpen, setAlertOpen] = useState(false);
  const [selectedProject, setSelectedProject] = useState({
    title: "Little movies",
    description: "A website for booking movies.",
    stack: "React JS, Express JS",
    url: "https://apps.little.africa/movies",
    logo: MoviesLogo,
  });
  const isMd = useMediaQuery((theme) => theme.breakpoints.up("sm"));
  const openProject = (project) => {
    setSelectedProject(project);
    setDialogOpen(true);
  };
  return (
    <Stack
      sx={{
        width: "100%",
        ...(isMd && { minHeight: "80vh" }),
        alignItems: "center",
        position: "relative",
      }}
    >
      <BgText>Projects</BgText>
      <Stack
        sx={{
          width: {
            xs: "90%",
            md: "80%",
          },
          alignSelf: "center",
          position: "relative",
        }}
        id="projectList"
        component={motion.div}
        initial={{ opacity: 0, y: 100 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ ease: "easeOut", duration: 0.7, delay: 2 }}
        spacing={2}
      >
        <Box
          component={motion.div}
          initial={{ height: 0 }}
          whileInView={{ height: "100%" }}
          viewport={{ once: true }}
          transition={{ ease: "easeOut", duration: 0.7 }}
          sx={{
            position: "absolute",
            width: "5px",
            borderRadius: "500px",
            bgcolor: "white",
          }}
        />
        <Typography variant="h5" pl={2} color="white" fontWeight={300}>
          Some of my projects
        </Typography>
        <Grid container spacing={{ xs: 1, md: 2 }}>
          {projects.map((project, index) => (
            <ProjectComponent
              delay={index * 0.5}
              onClick={() => openProject(project)}
              key={"project" + index}
              title={project.title}
            >
              <Box
                component="img"
                src={project.logo}
                sx={{ height: project.height }}
              />
            </ProjectComponent>
          ))}
        </Grid>

        <Snackbar open={alertOpen} onClose={() => setAlertOpen(false)}>
          <Alert severity="info">This project is coming soon</Alert>
        </Snackbar>
        <ProjectDialog
          open={projectDialogOpen}
          setOpen={setDialogOpen}
          selectedProject={selectedProject}
          setAlertOpen={setAlertOpen}
        />
      </Stack>
    </Stack>
  );
};
export default ProjectList;
