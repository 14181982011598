import { Box, Grid, Typography } from "@mui/material";
import "atropos/css";
import { motion } from "framer-motion";
import Atropos from "atropos/react";

const ProjectComponent = ({
  title,
  children,
  delay = 0,
  onClick = () => {},
}) => {
  return (
    <Grid
      item
      xs={6}
      sm={4}
      md={3}
      sx={{ display: "flex", flexDirection: "column", gap: "8px" }}
    >
      <Box
        component={motion.div}
        initial={{ y: 100, opacity: 0 }}
        whileInView={{ y: 0, opacity: 1 }}
        viewport={{ once: true }}
        transition={{ delay: delay, ease: "easeOut", duration: 0.7 }}
        width={"100%"}
        sx={{ cursor: "pointer" }}
        onClick={onClick}
      >
        <Box
          sx={{
            position: "relative",
            width: "100%",
            aspectRatio: "5/3.5",
            borderRadius: "10px",
          }}
          component={Atropos}
          shadowScale={0.8}
          rotateXMax={5}
          rotateYMax={5}
        >
          <Box
            sx={{
              width: "100%",
              height: "100%",
              position: "absolute",
              borderRadius: "10px",
              bgcolor: "white",
            }}
            data-atropos-offset="0"
          ></Box>
          <Box
            sx={{
              width: "100%",
              height: "100%",
              position: "absolute",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
            data-atropos-offset="5"
          >
            {children}
          </Box>
        </Box>
      </Box>
      <Typography color="white" fontWeight={300} sx={{ alignSelf: "center" }}>
        {title}
      </Typography>
    </Grid>
  );
};
export default ProjectComponent;
