import { Grid, Stack, Typography } from "@mui/material";
import Skills from "./skills";
import Hello from "./hello";
import { motion } from "framer-motion";
import BgText from "./bgText";

const About = () => {
  return (
    <Stack
      sx={{
        width: "100%",
        minHeight: "100vh",
        alignItems: "center",
        justifyContent: "center",
        position: "relative",
      }}
      id="about"
    >
      <Stack
        direction="row"
        width={"100%"}
        justifyContent="space-between"
        sx={{
          position: "absolute",
          top: 0,
        }}
        component={motion.div}
        initial={{
          opacity: 0,
        }}
        animate={{
          opacity: 1,
        }}
        transition={{ delay: 2, duration: 0.7 }}
      >
        <Typography
          variant="h6"
          fontWeight={300}
          m={2}
          sx={{ cursor: "pointer", userSelect: "none", color: "white" }}
        >
          DO
        </Typography>
      </Stack>
      <motion.div
        initial={{
          opacity: 0,
        }}
        animate={{
          opacity: 1,
        }}
        transition={{ delay: 2, duration: 0.7 }}
      >
        <BgText>About me</BgText>
      </motion.div>
      <Grid container sx={{ width: "80%" }}>
        <Grid
          item
          xs={12}
          md={6}
          sx={{
            display: "flex",
            justifyContent: "start",
            height: {
              xs: "fit-content",
              md: "50vh",
            },
          }}
        >
          <Hello />
        </Grid>
        <Grid
          item
          xs={12}
          md={6}
          sx={{
            display: "flex",
            justifyContent: { xs: "start", md: "end" },
          }}
        >
          <Skills />
        </Grid>
      </Grid>
    </Stack>
  );
};
export default About;
